.input-group {
  margin-bottom: 1rem !important;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  width: auto;
}

.mb-3 {
  margin-bottom: 1rem!important;
}

.input-group-text {
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
}

.form-control {
  display: block;
  width: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input-group>.form-control, .input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: auto;
  min-width: 0;
}

form .button {
  margin-top: 1.75rem;
}

.h2 {
  margin-top: 3rem;
}
