
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: var(--redux-color);
  height: 5rem;
}

nav section {
  width: 100%;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
}

nav section h1,
nav section {
  color: rgb(114, 110, 110);
}

nav .logo {
  width: 2rem;
}

nav .left, nav .right {
  display: flex;
  align-items: center;
}

nav .button,
nav a,
nav a:active {
  font-weight: 700;
  padding: 0.25rem 1.5rem;
  border-radius: 4px;
  color: white !important;
  background: #316161;
  cursor: pointer;
}

a:-webkit-any-link {
  text-decoration: none;
}

nav .button:hover,
nav a:hover {
  color: white;
  background: #608385;
}

@media (max-width: 600px) {
  nav .right {
    display: none;
  }
}
