.add {
  position: fixed;
  width: 5rem;
  height: 5rem;
  right: 1rem;
  bottom: 1rem;
  line-height: 4.5rem;
  font-size: 4rem;
  text-align: center;
}

.div .hidden {
  display: none;
}

.post-excerpt .button {
  margin-top: 1.75rem;
}

.author {
  margin-bottom: 1.5rem;
}
