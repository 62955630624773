:root {
  --redux-color: #D0DCDC;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 1.1rem;
}

section {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.5rem;
}

.post h2 {
  font-size: 2.5rem;
  margin-bottom: 5px;
}

.post-excerpt {
  padding: 1rem 0.25rem;
  border-bottom: 2px solid var(--redux-color);
  border-radius: 7px;
}

.post h3,
.post-excerpt h3 {
  font-size: 1.5rem;
}

p .post-content {
  margin-top: 10px;
  word-break: break-all;
}

a,
a:active {
  font-weight: 700;
  color: #316161;
  cursor: pointer;
}

a:-webkit-any-link {
  text-decoration: none;
}

a:hover {
  color: #608385;
}

button.reaction-button {
  border: 1px solid #e0e3e9;
  padding: 5px 10px;
  margin: 5px 6px 10px;
  border-radius: 4px;
  white-space: nowrap;
}

.button {
  display: inline-block;
  background: #316161;;
  color: white;
  border-radius: 4px;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
}

button:disabled,
button:disabled:hover {
  opacity: 0.5;
}

.page {
  text-align: center;
  margin: 2em;
}
